import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { FcKey } from 'react-icons/fc';
import { CgCloseO } from 'react-icons/cg';
import bg2 from './assets/ju2.jpg';
import bg3 from './assets/ju3.jpg';

const useStyles = makeStyles({
	main: {
		backgroundImage: `url(${bg3})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		width: '100vw',
		height: '100vh',
		opacity: '60%',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	timerContainer: {
		position: 'fixed',
		top: '50%',
	},
	timerText: {
		fontWeight: 700,
		color: '#000',
		opacity: '100%',
	},
	footer: {
		position: 'fixed',
		bottom: '5%',
	},
});

function App() {
	const classes = useStyles();

	const [dialog, setDialog] = useState(false);

	const [timerDias, setTimerDias] = useState('00');
	const [timerHoras, setTimerHoras] = useState('00');
	const [timerMinutos, setTimerMinutos] = useState('00');
	const [timerSegundos, setTimerSegundos] = useState('00');

	let interval = useRef();

	const startTimer = () => {
		interval = setInterval(() => {
			// const dataAgora = new Date().getTime()
			// const diferenca = dataFinal - dataAgora

			// const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))
			// // const horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
			// const horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
			// const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60))
			// const segundos = Math.floor((diferenca % (1000 * 60)) / 1000)

			const then = moment('04/05/2021 23:59:59', 'DD/MM/YYYY hh:mm:ss');
			const now = moment();
			const diferenca = moment(then - now);

			const dias = diferenca.format('D');
			const horas = Number(diferenca.format('HH')) + 3;
			const minutos = diferenca.format('mm');
			const segundos = diferenca.format('ss');

			if (diferenca < 0) {
				clearInterval(interval.current);
			} else {
				setTimerDias(dias);
				setTimerHoras(horas);
				setTimerMinutos(minutos);
				setTimerSegundos(segundos);
			}
		}, 1000);
	};

	useEffect(() => {
		setDialog(true);
	}, []);

	useEffect(() => {
		startTimer();
		return () => {
			clearInterval(interval.current);
		};
	});

	return (
		<>
			<div className={classes.main} />
			<Grid container justify='center' alignItems='center' className={classes.timerContainer}>
				<Grid item container justify='space-evenly'>
					<Grid item>
						<Grid container alignItems='center' direction='column'>
							<Typography variant='h3' className={classes.timerText}>
								00
							</Typography>
							<Typography variant='caption' className={classes.timerText}>
								Dia
							</Typography>
						</Grid>
					</Grid>

					<Typography variant='h3' className={classes.timerText}>
						:
					</Typography>

					<Grid item>
						<Grid container alignItems='center' direction='column'>
							<Typography variant='h3' className={classes.timerText}>
								00
							</Typography>
							<Typography variant='caption' className={classes.timerText}>
								Horas
							</Typography>
						</Grid>
					</Grid>

					<Typography variant='h3' className={classes.timerText}>
						:
					</Typography>

					<Grid item>
						<Grid container alignItems='center' direction='column'>
							<Typography variant='h3' className={classes.timerText}>
								{timerMinutos}
							</Typography>
							<Typography variant='caption' className={classes.timerText}>
								Minutos
							</Typography>
						</Grid>
					</Grid>

					<Typography variant='h3' className={classes.timerText}>
						:
					</Typography>

					<Grid item>
						<Grid container alignItems='center' direction='column'>
							<Typography variant='h3' className={classes.timerText}>
								{timerSegundos}
							</Typography>
							<Typography variant='caption' className={classes.timerText}>
								Segundos
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid container justify='center' className={classes.footer} direction='column' alignItems='center'>
					<Alert icon={false}>
						<Grid item>
							<Typography variant='caption'>Dê um &#9749; ao programador (R$ 1,00)</Typography>
						</Grid>
						<Grid item>
							<Typography variant='caption' style={{ fontWeight: 700, color: '#000' }}>
								PIX: thiagomunich@gmail.com
							</Typography>
						</Grid>
					</Alert>
				</Grid>
			</Grid>

			<Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
				<DialogTitle>
					<Grid container justify='center'>
						<Typography variant='h5'>#JULIETTECAMPEÃ &#127797;</Typography>
					</Grid>
				</DialogTitle>

				<DialogContent>
					<Grid container>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography variant='caption'>Gostou da ideia? </Typography>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography variant='caption'>Dê um cafezinho ao programador!</Typography>
						</Grid>
						<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
							<Typography variant='caption'>R$ 1,00 &#9749;</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems='center' justify='center' style={{ marginTop: 15, marginBottom: 15 }}>
						<FcKey size={20} />
						<Typography variant='caption' style={{ fontWeight: 700, color: '#7D9B1C' }}>
							PIX: thiagomunich@gmail.com
						</Typography>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid container justify='center'>
						<CgCloseO onClick={() => setDialog(false)} />
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default App;
